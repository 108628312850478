import {
  Box,
  FilledInput,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import useModal from "../../../hooks/use-modal";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../../components/buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  formatUZB,
  numberCheck,
  numberCleaner,
} from "../../../utils/number-formatter";
import { priceFromatter } from "../../../utils/price-formatter";
import useAddEmployee from "../../../services/corp-panel/use-add-employee";
import { useGetClientProfile } from "../../../services/queries/use-get-client-profile";
import useGetCorpClients from "../../../services/corp-panel/use-get-corp-clients";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import queryClient from "../../../configs/react-query.config";

interface AddEmployeeProps {
  onClose: () => void;
}

const AddEmployee: React.FC<AddEmployeeProps> = ({ onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [success, setSuccess] = useState("");

  const profile = useGetClientProfile();
  const addEmployee = useAddEmployee();

  const amountLimit = useModal();

  const formSchema = useMemo(() => {
    const schema = {
      phone: yup.string().required(t("required field")),
      amount: yup.number().nullable(),
      repeatability: yup
        .string()
        .oneOf(["month", "once", "unlimit"], t("must_be_either_month_or_one"))
        .required(t("required field")),
    };

    if (amountLimit?.isOpen) {
      schema.amount = yup.number().min(1000).required(t("required field"));
    }

    return yup.object().shape(schema);
  }, [amountLimit?.isOpen]);

  const form = useForm<{
    phone: string;
    amount: number;
    repeatability: "month" | "once" | "unlimit";
  }>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      repeatability: "unlimit",
      phone: "+998",
    },
  });

  useEffect(() => {
    if (amountLimit?.isOpen) {
      form.setValue("amount", 0);
      return form.setValue("repeatability", "month");
    }

    return form.setValue("repeatability", "unlimit");
  }, [amountLimit?.isOpen]);

  const submit = (value: {
    phone: string;
    amount: number;
    repeatability: "month" | "once" | "unlimit";
  }) => {
    if (profile?.data?.comp_id) {
      addEmployee.mutate(
        {
          phone: value.phone,
          amount: value.amount || undefined,
          repeatability: value.repeatability,
          is_manager: false,
          author_id: profile?.data?.comp_id,
          author_type: "corp",
        },
        {
          onSuccess: () => {
            toast.success(<Typography>{t("Successfully added")}</Typography>);
            onClose();
          },
        }
      );
    }
  };

  return (
    <Stack
      component="form"
      sx={{ pb: 2.5 }}
      onSubmit={form.handleSubmit(submit)}
    >
      <InputLabel sx={{ mt: 2.5, mb: 1 }}>
        {t("mobile.employee_detail.phone_number")}
      </InputLabel>
      <Controller
        name="phone"
        control={form.control}
        render={({ field }) => {
          return (
            <>
              <FilledInput
                value={formatUZB(numberCleaner(field?.value || ""))}
                placeholder="+998 (00) 000-00-00"
                onChange={(value: ChangeEvent<HTMLInputElement>) => {
                  const number = numberCheck(value.target.value);

                  if (number) {
                    value.target.value = numberCleaner(number);
                    field.onChange(value);
                  }
                }}
              />
              <FormHelperText error={!!form?.formState?.errors?.phone?.message}>
                {form?.formState?.errors?.phone?.message}
              </FormHelperText>
            </>
          );
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1.25,
          my: 2.5,
        }}
      >
        <Switch
          checked={amountLimit?.isOpen}
          onChange={amountLimit?.toggle}
          id="employee-amount-limit"
        />
        <Box>
          <InputLabel
            htmlFor="employee-amount-limit"
            sx={{ cursor: "pointer" }}
          >
            {t("amount_limit")}
          </InputLabel>
          <FormHelperText>
            {t("only_entered_amount_can_be_used")}
          </FormHelperText>
        </Box>
      </Box>
      {amountLimit?.isOpen && (
        <>
          <Controller
            name="amount"
            control={form.control}
            render={({ field }) => {
              return (
                <Box mb={2.5} width="100%">
                  <FilledInput
                    placeholder={t("Enter amount")}
                    type="string"
                    value={priceFromatter(field?.value || "").trim()}
                    onChange={(e) =>
                      field?.onChange(numberCleaner(e.target.value || ""))
                    }
                    fullWidth
                  />
                  <FormHelperText
                    error={!!form?.formState?.errors?.amount?.message}
                  >
                    {form?.formState?.errors?.amount?.message}
                  </FormHelperText>
                </Box>
              );
            }}
          />
          <InputLabel sx={{ mb: 1 }}>{t("repeatability")}</InputLabel>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={form.watch("repeatability")}
              data-sentry-mask
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }}
              >
                <Box
                  component="label"
                  htmlFor="once"
                  sx={{
                    p: "5px 16px",
                    borderRadius: "12px",
                    bgcolor: theme.palette.secondary.main,
                    ":hover": { bgcolor: theme.palette.secondary.dark },
                    width: "100%",
                    cursor: "pointer",
                    mr: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FormControlLabel
                    value="once"
                    control={
                      <Radio
                        id="once"
                        color="info"
                        {...form.register("repeatability")}
                      />
                    }
                    label={t("repeatability.one")}
                  />
                </Box>
                <Box
                  component="label"
                  htmlFor="month"
                  sx={{
                    p: "5px 16px",
                    borderRadius: "12px",
                    bgcolor: theme.palette.secondary.main,
                    ":hover": { bgcolor: theme.palette.secondary.dark },
                    width: "100%",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FormControlLabel
                    value="month"
                    control={
                      <Radio
                        id="month"
                        color="info"
                        {...form.register("repeatability")}
                      />
                    }
                    label={t("repeatability.month")}
                  />
                </Box>
              </Box>
            </RadioGroup>
          </Box>
        </>
      )}
      <Button sx={{ mt: 2.5 }} type="submit">
        {t("Add")}
      </Button>
    </Stack>
  );
};

export default AddEmployee;
