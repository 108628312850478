import React from "react";
import useGetClientDetail from "../../../services/corp-panel/use-get-client-detail";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Trash2, Edit } from "lucide-react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useConfirm from "../../../components/modals/confirmation-modal/confirmation-modal";
import { formatUZB } from "../../../utils/number-formatter";
import useGetClientRestrictions from "../../../services/corp-panel/use-get-client-restrictions";
import MobileDrawer from "../../../components/mobile/mobile-drawer/mobile-drawer";
import useModal from "../../../hooks/use-modal";
import EditEmpoyeeDetail from "./edit-empoyee-detail";
import Edit2Icon from "../../../components/icons/edit2";
import { priceFromatter } from "../../../utils/price-formatter";
import i18n from "../../../i18n";
import useDeleteCorpClient from "../../../services/corp-panel/use-delete-corp-client";

const EmployeeDetail: React.FC<{ client_id: number | undefined }> = ({
  client_id,
}) => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const editModal = useModal();

  const clientDetail = useGetClientDetail(client_id);
  const clientRestrictions = useGetClientRestrictions(client_id);
  const deleteEmployee = useDeleteCorpClient();

  return (
    <>
      <Stack>
        {[
          {
            title: "mobile.employee_detail.phone_number",
            value: formatUZB(clientDetail?.data?.phone),
          },
          {
            title: "mobile.employee_detail.amount_of_each_period",
            value: priceFromatter(clientDetail?.data?.amount || 0),
          },
          {
            title: "mobile.employee_detail.remaining_amount",
            value: priceFromatter(clientDetail?.data?.balance || 0),
          },
          {
            title: "mobile.employee_detail.last_trip",
            value: !!clientDetail?.data?.last_activity
              ? dayjs(clientDetail?.data?.last_activity)
                  .locale(i18n.language == "uz" ? "uz-latn" : i18n.language)
                  .format("DD MMMM, dddd")
              : "",
          },
          {
            title: t("repeatability"),
            value: clientDetail?.data?.repeatability
              ? t(`repeatability.${clientDetail?.data?.repeatability}`)
              : "",
          },
          {
            title: "mobile.employee_detail.last_modified",
            value: dayjs(clientDetail?.data?.updated_time)
              .locale(i18n.language == "uz" ? "uz-latn" : i18n.language)
              .format("DD MMMM, dddd"),
          },
        ]?.map((item) => (
          <Box
            sx={{
              borderBottom: "1px solid #F9F9F9",
              py: 1.5,
            }}
            key={item.title}
          >
            <Typography
              mb={1.25}
              fontSize={13}
              fontWeight={400}
              lineHeight="9px"
              color="#8C8C8C"
            >
              {t(item?.title)}
            </Typography>
            {["string", "number"].includes(typeof item?.value) ? (
              <Typography fontSize={15} fontWeight={500} lineHeight="11px">
                {item?.value || t("n/a")}
              </Typography>
            ) : (
              <Skeleton
                width={Math.floor(Math.random() * (130 - 70 + 1)) + 70}
              />
            )}
          </Box>
        ))}
      </Stack>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignItems: "center",
          gap: 0.75,
          pt: 2.5,
          pb: 2,
        }}
      >
        <ButtonBase
          sx={{
            py: "14px",
            bgcolor: "#F8F8F8",
            borderRadius: 1.25,
            color: "#FF3B30",
          }}
          onClick={async () => {
            const isDelete = await confirm({
              description: t("Do you want delete name", {
                name: clientDetail?.data?.name,
              }),
              confirmBtnLabel: t("delete"),
              confirmBtnProps: {
                color: "error",
              },
            });

            if (isDelete && client_id) {
              console.log("deleteCorpClient?.mutate(clientDetail?.id)");

              deleteEmployee.mutate(client_id);
            }
          }}
          disabled={!!deleteEmployee?.isLoading}
        >
          {deleteEmployee?.isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Trash2 style={{ marginRight: 6 }} /> {t("delete")}
            </>
          )}
        </ButtonBase>
        <ButtonBase
          sx={{
            py: "14px",
            bgcolor: "#F8F8F8",
            borderRadius: 1.25,
            color: "#007AFF",
          }}
          onClick={editModal?.open}
        >
          <Edit style={{ marginRight: 6 }} /> {t("edit")}
        </ButtonBase>
      </Box>

      <MobileDrawer
        drawerTitle={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Edit2Icon />
            {clientDetail?.data?.name}
          </Box>
        }
        leftTitle
        open={!!editModal?.isOpen}
        onClose={editModal?.close}
      >
        <EditEmpoyeeDetail client_id={client_id} onClose={editModal?.close} />
      </MobileDrawer>
    </>
  );
};

export default EmployeeDetail;
